// import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Home from './screens/home/Home.js';
import Contact from './screens/contact/Contact.js';
import Experience from './screens/experience/Experience.js';
import JobsPage from './screens/jobScrapper/JobsPage.js'; // Import JobsPage

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css'; // Global styles

import emailjs from 'emailjs-com';

emailjs.init({
  publicKey: 'hQlTc-DR44Rw5ebKP',
  blockHeadless: true,
  blockList: {
    list: ['foo@emailjs.com', 'bar@emailjs.com'],
    watchVariable: 'userEmail',
  },
  limitRate: {
    id: 'app',
    throttle: 10000,
  },
});

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/jobs" element={<JobsPage />} /> {/* Add JobsPage route */}
      </Routes>
    </Router>
  );
}

export default App;
