import React, { useEffect, useState } from 'react';
import { collection, getDoc, getDocs, doc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Badge, InputGroup, FormControl } from 'react-bootstrap';
import './JobsPage.css';
import JobItem from './components/JobItem';

const JobsPage = () => {
    const [jobTitles, setJobTitles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('Any');
    const [selectedLocation, setSelectedLocation] = useState('Any');
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [lastScraped, setLastScraped] = useState(null);
    const [requiresCitizenship, setRequiresCitizenship] = useState(false);
    const [excludeAgencies, setExcludeAgencies] = useState(false);
    const [minYearsExperience, setMinYearsExperience] = useState('Any');
    const [dateRange, setDateRange] = useState('');
    const [newSinceLastScrape, setNewSinceLastScrape] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch job titles on component mount
    useEffect(() => {
        const fetchJobTitles = async () => {
            try {
                const jobdataCollection = collection(db, 'jobdata');
                const jobTitleSnapshot = await getDocs(jobdataCollection);
                const titles = jobTitleSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                console.log("Fetched job titles:", titles);
                setJobTitles(['Any', ...titles.map(title => title.id)]);
            } catch (error) {
                console.error("Error fetching job titles:", error);
            }
        };

        fetchJobTitles();
    }, []);

    // Fetch locations based on selected job title
    useEffect(() => {
        const fetchLocations = async () => {
            if (!selectedTitle || selectedTitle === 'Any') {
                setLocations(['Any']);
                return;
            }

            try {
                console.log("Fetching locations for:", selectedTitle);

                const jobTitleDocRef = doc(db, 'jobdata', selectedTitle);
                const jobTitleDocSnap = await getDoc(jobTitleDocRef);

                if (jobTitleDocSnap.exists()) {
                    const docData = jobTitleDocSnap.data();
                    const locs = docData.locations || [];

                    console.log("Fetched locations:", locs);
                    setLocations(['Any', ...locs]);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        if (selectedTitle) {
            fetchLocations();
        }
    }, [selectedTitle]);

    // Fetch jobs based on selected title and location
    useEffect(() => {
        const fetchJobs = async () => {
            if ((!selectedTitle || selectedTitle === 'Any') && (!selectedLocation || selectedLocation === 'Any')) {
                console.error("Job title or location is undefined.");
                return;
            }

            try {
                console.log("Fetching jobs for:", selectedTitle, selectedLocation);

                const jobsCollection = selectedTitle !== 'Any'
                    ? collection(db, 'jobdata', selectedTitle, selectedLocation !== 'Any' ? selectedLocation : '')
                    : collection(db, 'jobdata');

                const jobSnapshot = await getDocs(jobsCollection);

                if (jobSnapshot.empty) {
                    console.log("No jobs found for", selectedTitle, "in", selectedLocation);
                }

                const jobList = jobSnapshot.docs.map(doc => {
                    const jobData = doc.data();
                    const jobDate = jobData.date ? new Date(jobData.date) : null;
                    const roundedDate = jobDate && !isNaN(jobDate) ? roundToNearestTenMinutes(jobDate).toISOString() : null;

                    return {
                        id: doc.id,
                        ...jobData,
                        roundedDate
                    };
                });

                console.log("Fetched jobs:", jobList);
                setJobs(jobList);

                // Determine the last scrape date by finding the most recent job date
                const mostRecentDate = jobList.length > 0 ? Math.max(...jobList.map(job => new Date(job.roundedDate).getTime())) : null;
                setLastScraped(mostRecentDate ? new Date(mostRecentDate).toISOString() : null);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            }
        };

        if (selectedTitle && selectedLocation) {
            fetchJobs();
        }
    }, [selectedTitle, selectedLocation]);

    // Filter jobs based on various criteria, including the search query
    useEffect(() => {
        const filterJobs = () => {
            const excludeTitles = ["Senior", "Sr.", "Sr", "Principal", "Staff", "Lead"];
            const agenciesToExclude = ["Jobot", "ClickJobs.io", "Actalent","Dice"];

            let filtered = jobs;

            if (requiresCitizenship) {
                filtered = filtered.filter(job => job.requires_citizenship === false);
            }

            if (excludeAgencies) {
                filtered = filtered.filter(job => !agenciesToExclude.includes(job.company));
            }

            if (minYearsExperience !== 'Any') {
                filtered = filtered.filter(job => {
                    const normalizedExperience = normalizeExperience(job.years_of_experience_detailed);
                    return normalizedExperience <= parseInt(minYearsExperience) &&
                        !excludeTitles.some(keyword => job.title.toLowerCase().includes(keyword.toLowerCase()));
                });
            }

            if (dateRange) {
                filtered = filtered.filter(job => filterByDateRange(job.date, dateRange));
            }

            if (newSinceLastScrape && lastScraped) {
                filtered = filtered.filter(job => new Date(job.roundedDate).toISOString() >= lastScraped);
            }

            // Filter by search query
            if (searchQuery) {
                const lowercasedQuery = searchQuery.toLowerCase();
                filtered = filtered.filter(job => 
                    (job.title && job.title.toLowerCase().includes(lowercasedQuery)) ||
                    (job.company && job.company.toLowerCase().includes(lowercasedQuery)) ||
                    (job.description && job.description.toLowerCase().includes(lowercasedQuery))
                );
            }

            setFilteredJobs(filtered);
        };

        if (jobs.length > 0) {
            filterJobs();
        }
    }, [jobs, requiresCitizenship, excludeAgencies, minYearsExperience, dateRange, newSinceLastScrape, searchQuery]);

    // Function to normalize experience similar to the Python function
    const normalizeExperience = (exp) => {
        if (!exp || typeof exp !== 'string') return null; // Return null if exp is not a string

        exp = exp.toLowerCase().trim();

        if (exp === 'none' || exp === '0') return 0;
        if (exp.includes('-')) return parseInt(exp.split('-')[0]);
        if (exp.includes('+')) return parseInt(exp.replace('+', ''));
        return parseInt(exp) || null;
    };

    // Function to filter by date range
    const filterByDateRange = (date, range) => {
        const jobDate = new Date(date);
        const now = new Date();

        switch (range) {
            case '24h':
                return jobDate >= new Date(now.setDate(now.getDate() - 1));
            case 'week':
                return jobDate >= new Date(now.setDate(now.getDate() - 7));
            case 'month':
                return jobDate >= new Date(now.setMonth(now.getMonth() - 1));
            default:
                return true;
        }
    };

    // Function to round the date to the nearest 10 minutes
    const roundToNearestTenMinutes = (date) => {
        const ms = 1000 * 60 * 10; // 10 minutes in milliseconds
        return new Date(Math.round(date.getTime() / ms) * ms);
    };

    const toggleBadge = (filter, setFilter) => {
        setFilter(!filter);
    };

    return (
        <div className="jobs-page mx-3">
            <h1>Job Postings</h1>
            
            <div className="filter-section ">
                <div className="job-search">
                    <h2>Job Search</h2>  
                    <hr />
                      <div className="dropdown-filters">
                          <label className='w-100'>
                              Job Title:
                              <select value={selectedTitle} onChange={(e) => setSelectedTitle(e.target.value)}>
                                  {jobTitles.map((title, index) => (
                                      <option key={index} value={title}>{title}</option>
                                  ))}
                              </select>
                          </label>
                      </div>
                      <div className="dropdown-filters">
                          {selectedTitle && locations.length > 0 && (
                              <label className='w-100'>
                                  Location:
                                  <select value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)}>
                                      {locations.map((location, index) => (
                                          <option key={index} value={location}>{location}</option>
                                      ))}
                                  </select>
                              </label>
                          )}
                    </div>
                </div>

                <div className="filters">
                    <h2>Filters</h2>
                    <hr />
                    <div className="dropdown-filters"> 
                        <label>
                            Minimum Years of Experience:
                            <select value={minYearsExperience} onChange={(e) => setMinYearsExperience(e.target.value)}>
                                <option value="Any">Any</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </label>
                    </div>
                    <div className="dropdown-filters">
                        <label>
                            Date Range:
                            <select value={dateRange} onChange={(e) => setDateRange(e.target.value)}>
                                <option value="">Any Time</option>
                                <option value="24h">Past 24 Hours</option>
                                <option value="week">Past Week</option>
                                <option value="month">Past Month</option>
                            </select>
                        </label>
                    </div>

                    <div className="badge-filters">
                        <Badge
                            className={requiresCitizenship ? "badge-selected" : "badge-unselected"}
                            onClick={() => toggleBadge(requiresCitizenship, setRequiresCitizenship)}
                            style={{ cursor: 'pointer' }}
                        >
                            Exclude Jobs Requiring Citizenship
                        </Badge>

                        <Badge
                            className={excludeAgencies ? "badge-selected" : "badge-unselected"}
                            onClick={() => toggleBadge(excludeAgencies, setExcludeAgencies)}
                            style={{ cursor: 'pointer' }}
                        >
                            Exclude Agencies
                        </Badge>

                        <Badge
                            className={newSinceLastScrape ? "badge-selected" : "badge-unselected"}
                            onClick={() => toggleBadge(newSinceLastScrape, setNewSinceLastScrape)}
                            style={{ cursor: 'pointer' }}
                        >
                            New Since Last Scrape
                        </Badge>
                    </div>
                </div>
            </div>
                    <h2>Search</h2>
                    <hr />
                    <InputGroup className="search-filter my-2">
                        <FormControl
                            placeholder="Search for company, title..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>


            <p>{filteredJobs.length} results found</p>  {/* Display the number of filtered results */}
            <hr />

            {selectedLocation && filteredJobs.length > 0 && (
                <div className="job-listings">
                    <h2>{`Jobs for ${selectedTitle} in ${selectedLocation}`}</h2>
                    {lastScraped && <p>Last scraped: {new Date(lastScraped).toLocaleString()}</p>}
                    <div className="jobs-list">
                        {filteredJobs.map((job, index) => (
                            <JobItem key={index} job={job} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default JobsPage;
