import React, { useState } from 'react';
import './JobItem.css';

const JobItem = ({ job }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    // Ensure the description exists and is a string
    const description = typeof job.description === 'string' ? job.description : '';

    return (
        <div className={`job-item ${isExpanded ? 'expanded' : ''}`}>
            <h3>{job.title}</h3>
            <h4>Company: {job.company}</h4>
            <h5>Years of Experience: {job.years_of_experience_detailed || 'Experience level not found'}</h5>
            <p className="description">
                {isExpanded ? description : `${description.substring(0, 200)}...`}
                {description.length > 200 && (
                    <button onClick={toggleReadMore} className="toggle-button">
                        {isExpanded ? 'Show Less' : 'Read More'}
                    </button>
                )}
            </p>
            <p>Rounded Timestamp: {job.roundedDate ? new Date(job.roundedDate).toLocaleString() : 'N/A'}</p>
            <a href={job.link} target="_blank" rel="noopener noreferrer">View Job</a>
        </div>
    );
};

export default JobItem;
